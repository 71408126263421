body {
  margin: 0;
  padding: 0;
  font-family: KaiTi;  
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login .head {
  display: flex;
  justify-content: space-around;
  font-size: 0.5rem;
  width: 100%;
}

.login .head span {
  flex-grow: 1;
  text-align: center;
  margin: 0 0.5rem;
}

.login .head .on {
  border-bottom: 2px solid darkgray;
  padding-bottom: 0.1rem;
}

.login .body {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  align-items: center;
  width: 80%;
}

.login .body .btn {
  text-align: center;
  padding: 0.1rem 0;
  font-size: 0.6rem;
  background: darkgray;
  border-radius: 0.1rem;
  margin-top: 0.5rem;
  color: white;
  width: 100%;
}

input::-webkit-input-placeholder{
  color:darkgray;
}
input::-moz-placeholder{   /* Mozilla Firefox 19+ */
  color:darkgray;
}
input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
  color:darkgray;
}
input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
  color:darkgray;
}
